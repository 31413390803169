import { TextsNews } from '@front/shared/ds';
import { Section } from '@shared/master-types';
import { useRouter } from 'next/router';
import React from 'react';

import useFetchNews, { ETextNewsSortFor } from '../../../hooks/useWPposts';
import { useAppDispatch } from '../../../store/store.hooks';
import toCardButtons from '../../../utils/toCardButtons';

export type TTextNewsCardRowWpConnected = Extract<
  Required<Section>['rows'][number],
  { blockType: 'texts-news-row-wp' }
>;

const TextsNewsRowWPConnected: React.FC<
  TTextNewsCardRowWpConnected
> = props => {
  const { newsApiDomain, newsApiEndpoint, title, button, sortFor } = props;

  const router = useRouter();
  const appDispatch = useAppDispatch();
  const currentLocale = String(router.query.locale || '');

  const { newsPosts, error, loading } = useFetchNews(
    newsApiDomain,
    newsApiEndpoint,
    false,
    { sortFor: sortFor as ETextNewsSortFor },
  );

  if (error) {
    return null;
  }

  return (
    <TextsNews
      buttons={toCardButtons(button, currentLocale, appDispatch)}
      title={title}
      isLoading={loading}
      newsItems={newsPosts}
    />
  );
};

export default TextsNewsRowWPConnected;
